<script>
export default {
    props: {
		data: {
            type: Array,
            required: true,
        },
	},
}
</script>

<template>
    <ol role="list" class="flex items-center flex-wrap">
        <li v-for="(breadcrumb, idx) in data" :key="idx">
            <div class="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                    class="h-5 flex-shrink-0 text-gray-400" v-if="idx != 0">
                    <path fill-rule="evenodd"
                        d="M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z"
                        clip-rule="evenodd" />
                </svg>
                <NuxtLink :to="localePath({ name: 'slug', params: { slug: breadcrumb.url.split('/') } })" class="text-gray-500 text-xs mx-1">
                    <i :class="breadcrumb.icon" v-if="breadcrumb.icon"></i>
                    {{ breadcrumb.name }}
                </NuxtLink>
            </div>
        </li>
    </ol>
</template>